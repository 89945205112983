import React from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useForm from "./UseForm.js";
import { validator } from "./Validator.js";
import Background from "../../assets/img/hotel.jpg";
import Card from "@material-ui/core/Card";
import "assets/css/material-dashboard-react.css";
import Logo from "../../assets/img/hotel-logo.png";
// import { CircularProgress } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Popup from "components/Popup/Popup.js";
import { Link as TypoLink } from "@mui/material";
import { useHistory } from "react-router-dom";
import Api from "../../Api/ApiUtils";
import { useEffect } from "react";

const Login = () => {
  const style = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const style3 = {
    //height: "510px",
    width: "445px",
    left: "1041px",
    top: "113px",
    background: "#FFFFFF",
    position: "fixed",
    //margin:"90px auto",
    padding: 20,
  };
  const textStyle = {
    marginTop: 8,
    color: "#222222",
    fontSize: 14,
    textAlign: "center",
    pointer: "cur",
  };

  const avatarStyle = { padding: 20 };
  const btnstyle = { margin: "15px 0" };
  const initState = {
    email: "",
    password: "",
    loader: false,
  };

  const history = useHistory();

  const submit = () => {
    // console.log(" Submited");
  };
  const RegisterHandler = () => {
    history.push("/superadmin/register");
  };

  const contentList = () => {
    Api.contentList()
      .then((res) => {
        if (res.data.status === 1) {
          localStorage.setItem("ContentList", JSON.stringify(res.data));
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          console.log(err.msg);
        }
      });
  };
  //const [br, setBR] = React.useState(true);
  const [modalContext, setModalContext] = React.useState({
    show: false,
    type: "termsandcondition",
  });

  useEffect(() => {
    contentList();
  }, []);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    message,
    br,
    setBR,
    handleCloseMsg,
  } = useForm({
    initState,
    callback: submit,
    validator,
  });

  let isValidForm =
    Object.values(errors).filter((error) => typeof error !== "undefined")
      .length === 0;
  return (
    <Grid
      container
      style={{
        minWidth: "100%",
        height: "100vh",
      }}
    >
      <Popup
        openModal={modalContext}
        closeModal={() =>
          setModalContext((previousContext) => ({
            ...previousContext,
            show: false,
          }))
        }
      />
      <Grid item lg={12} sm={12} md={12} style={style}>
        <Card style={style3}>
          <Grid align="center">
            <Link to="/">
              <img src={Logo} style={avatarStyle} alt="fireSpot" />
            </Link>
            <h2>Sign In</h2>
          </Grid>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              name="email"
              placeholder="Enter username"
              defaultValue={state.email}
              onChange={handleChange}
              error={errors.email ? true : false}
              helperText={errors.email}
              onBlur={handleBlur}
              required
              fullWidth
              style={{
                height: "44",
                padding: "10px 0px",
              }}
            />
            <TextField
              label="Password"
              name="password"
              placeholder="Enter password"
              type="password"
              defaultValue={state.password}
              onChange={handleChange}
              error={errors.password ? true : false}
              helperText={errors.password}
              onBlur={handleBlur}
              required
              fullWidth
              style={{
                height: "44",
                padding: "10px 0px",
              }}
            />
            {/*} <FormControlLabel
                  control={
                  <Checkbox
                      name="checkedB"
                      color="primary"
                  />
                  }
                  label="Remember me"
                  style= {{
                    height:"44",
                    padding: '0px 0px',
                  }}
                />*/}
            <br />

            {/* {loader ? <center><CircularProgress align="centre" color="primary" /></center> : ""} */}
            {/* {console.log("Loader", state)} */}
            <center>
              {state.loader && (
                <CircularProgress align="centre" color="primary" />
              )}
            </center>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              disabled={!isValidForm}
              fullWidth
            >
              Sign In
            </Button>
            <Button
              // type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={RegisterHandler}
            >
              {/* <Link style={{ color: "#fff" }} to="/superadmin/register"> */}
              Register
              {/* </Link> */}
            </Button>

            <Typography style={textStyle}>
              <Link to="/superadmin/forgotpassword">Forgot password</Link>
            </Typography>
            <Typography style={textStyle}>
              By signing in or creating an account, you agree with our{" "}
              <TypoLink
                onClick={() => {
                  setModalContext({ show: true, type: "termsandcondition" });
                }}
              >
                Terms & conditions{" "}
              </TypoLink>{" "}
              and{" "}
              <TypoLink
                onClick={() => {
                  setModalContext({ show: true, type: "privacyPolicy" });
                }}
              >
                Privacy policy{" "}
              </TypoLink>
            </Typography>
            <Typography style={textStyle}>
              {" "}
              All rights reserved. Copyright (2023-2024) – HamroStay.com
            </Typography>
          </form>
        </Card>
        {message ? (
          <Snackbar
            place="tr"
            color="danger"
            // icon={AddAlert}
            message={message}
            open={br}
            setBR={setBR}
            closeNotification={handleCloseMsg}
            close
          />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default Login;
