import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ColorlibStepIcon, ColorlibStepIconRoot } from "./SteperIcon";
import { styled } from "@mui/material/styles";
const BoxTitle = styled(Typography)(({ theme, color }) => ({
  fontFamily: "Manrope !important",
  fontStyle: "normal",
  //fontSize: "28px",
  fontWeight: "800",
  lineHeight: "46px",
  marginBottom: "8px",
  marginTop: "24px",
  color: color ?? theme.palette.otherColor.lightBlack,
  [theme.breakpoints.down("md")]: {
    // fontSize: "22px",
    fontWeight: 600,
    lineHeight: "36px",
    marginTop: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    //fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    marginTop: "12px",
  },
}));
const steps = [
  {
    label: "Contact Information",
    description: `Your primary contact will be Hamro stay's point person for your business`,
  },
  {
    label: "Business Information",
    description: "Let us know your business name and location",
  },
  {
    label: "Choose Subscription",
    description: `Select from variety of subscription avilable`,
  },
  {
    label: "Identification submission",
    description: `upload proof for KYC`,
  },
];
const styles = (theme) => ({
  iconContainer: {
    // define styles for icon container
    transform: "scale(2)",
  },
});

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} active = {true}>
            <StepLabel StepIconComponent={ColorlibStepIcon} >
              <BoxTitle variant="h6" color="#FFF">
                {step.label}
              </BoxTitle>
            </StepLabel>
            <StepContent>
              <Typography>
                <BoxTitle color="#FFF">
                  {step.description}
                </BoxTitle>
              </Typography>
              {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}
