import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Api from "Api/ApiUtils";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import { useForm, Controller } from "react-hook-form";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress, Dialog, Divider
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../../src/assets/css/material-dashboard-react.css";
import Snackbar from "../../components/Snackbar/Snackbar";
import CategoryTable from "../../components/Table/CategoryTable.js";
import ImageGallery from "./ImageGallery";
import CustomAutoInput from "components/CustomInput/CustomAutoComplete";
import { Autocomplete } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const ControlledAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  _onChange,
  control,
  defaultValue,
  name,
  renderOption,
  value
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          multiple={true}
          value={value}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => _onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export default function MenuCategoryList() {
  const classes = useStyles();
  const listing = {
    search: "",
    page: 0,
    limits: 10,
    sortValue: "desc",
    sortField: "createdAt",
  };
  const user = JSON.parse(localStorage.getItem("HamroSuperAdminInfo"));
  const [categoryList, setcategoryList] = useState([]);
  const [recordCount, settotalRecords] = useState(0);
  const [tableParams, settableParams] = useState(listing);
  const [open, setOpen] = useState(false);
  const [openAdminCat, setOpenAdminCat] = useState(false);
  const [loader, setLoader] = useState(false);
  const [br, setBR] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    category_name: "",
    category_image: "",
    available_days: [],
    available_time_slot: []
  });
  const [notification, setNotification] = useState({
    type: "",
    message: "",
  });
  const [getCategoryDataCall, setGetCategoryDataCall] = useState(false);
  const [adminCatogories, setAdminCategories] = useState([]);

  const avilableDaysData = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const avilableTimeSlot = ["Morning", "Lunch", "Evening Snack", "Dinner", "24 Hours"];

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    category_name: Yup.string().required("Category Name is required"),
    category_image: Yup.mixed().when("id", {
      is: !null,
      then: Yup.mixed().required("image is required")
    }),
    //available_days: Yup.array().min(1, "Available date must be specified").of(Yup.string()),
    //available_time_slot: Yup.array().min(1, "Available time must be specified").of(Yup.string()).required("Available time must be specified")
  });
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    getMenuCategories();
    adminCategoryList();
  }, []);

  useEffect(() => {
    getMenuCategories();
  }, [tableParams]);

  const adminCategoryList = () => {
    const payload = {
      hotel_id: user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id,
    };
    const default_key = 1;
    Api.getMasterMenuCategory(payload, default_key)
      .then((res) => {
        if (res.data.status == 1) {
          setAdminCategories(res.data.data.rows);
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          setAdminCategories((prev) => ({
            ...prev,
          }));
        }
      });
  };


  const getMenuCategories = () => {
    let payload = { hotel_id: user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id }
    Api.categoryListing(tableParams, payload)
      .then((res) => {
        setLoader(false);

        if (res && res.data && res.data.data) {
          //res.data.data.rows.map((category) => {
          //   category_list.push({
          //     cImage: category.anc_cat_image,
          //     name: convertStringToCamelCase(category.name),
          //     available_time_slot: category.available_time_slot,
          //     id: category.id,
          //     hotel_id: category.hotel_id,
          //     createdAt: moment(category.createdAt).format("LL"),
          //   });
          // });
          setcategoryList(res.data.data.rows);

          settotalRecords(res.data.data.count)
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg ? res.data.msg : "Some error occured",
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          setBR(true);
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  }

  const fileValidator = (e) => {
    setValue("category_image", e.target.files[0]);
    trigger("category_image");
  };

  const handleCloseMsg = () => {
    setBR(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAdminCat(false);
  };

  const handlePagination = (e, page) => {
    settableParams((prev) => ({
      ...prev,
      page: page,
    }));
  };

  const handleLimit = (e) => {
    settableParams((prev) => ({
      ...prev,
      limits: e.target.value,
      page: 0,
    }));
  };

  const resetForm = () => {
    reset(
      {
        id: null,
        category_name: "",
        category_image: null,
        available_days: [],
        available_time_slot: []
      }
    );
    setSelectedCategory({
      category_name: "",
      category_image: null,
      available_days: [],
      available_time_slot: []
    });
    handleClose();
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    handleFormSubmit(data, e);
  };

  const handleFormSubmit = (payload, e) => {
    //e.preventDefault();
    payload.hotel_id = user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id;
    if (selectedCategory && selectedCategory.id)
      editHotelCategory(payload);
    else {
      Api.createHotelCategory(payload)
        .then((res) => {
          resetForm();
          if (res.data.status === 1) {
            setTimeout(() => {
              setBR(true);
              setNotification({
                type: "success",
                message: res.data.msg,
              });
            }, 1000);
            getMenuCategories();
          } else {
            setBR(true);
            setNotification({
              type: "danger",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          if (err && err.msg) {
            setBR(true);
            resetForm();
            setNotification({
              type: "danger",
              message: err.msg,
            });
          }
        });
    }
  };

  const addFromAdmin = (category, event) => {
    handleClose();
    category.parent_id = category.id;
    category.category_name = category.name;
    category.category_image = category.image;
    console.log(category);
    handleFormSubmit(category)
  }

  const setAvilableTimeSlot = (value) => {
    setValue("available_time_slot", value);
    setSelectedCategory({
      ...selectedCategory,
      available_time_slot: value
    });
    trigger("available_time_slot");
  };

  const setAvilableDaysSlot = (value) => {
    console.log(value);
    setValue("available_days", value);
    setSelectedCategory({
      ...selectedCategory,
      available_days: value
    });
    trigger("available_days");
  };

  const handleOpen = () => setOpen(true);

  const initiateEdit = (category) => {
    console.log(category);
    setSelectedCategory({
      id: category.id,
      category_name: category.name,
      available_days: category.available_days?.split(','),
      available_time_slot: category.available_time_slot?.split(',')
    });

    setValue("id", category.id);
    setValue("category_name", category.name);
    setValue("available_days", category.available_days?.split(','));
    setValue("available_time_slot", category.available_time_slot?.split(','));

    handleOpen();

  }

  // api for edit category
  const editHotelCategory = (editCategory) => {
    const payload = editCategory;
    payload.id = selectedCategory.id;
    payload.hotel_id = user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id;
    Api.editHotelCategoryDetails(payload)
      .then((res) => {
        resetForm();
        if (res.data.status === 1) {
          setTimeout(() => {
            setBR(true);
            setNotification({
              type: "success",
              message: res.data.msg,
            });
          }, 1000);
          //setSelectedCategory(false);
          getMenuCategories();
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          setBR(true);
          resetForm();
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  };

  // delete category
  const deleteCategory = (deleteDataId) => {
    console.log(deleteDataId);
    const payload = {
      id: deleteDataId,
      hotel_id: user[0]?.super_admin_users[0]?.hotel_id ?? user[0]?.hotel_id,
    };
    Api.deleteHotelCategory(payload)
      .then((res) => {
        if (res.data.status == 1) {
          getMenuCategories();

          setTimeout(() => {
            setBR(true);
            setNotification({
              type: "success",
              message: res.data.msg,
            });
          }, 1000);
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        const error = err?.message || err?.res?.message || err;

        setBR(true);
        setNotification({
          type: "danger",
          message: err.msg,
        });
      });
  };

  function handleChange(evt) {
    console.log("new value", evt.target.value);
  }

  const handleSearch = (e) => {
    settableParams((prev) => ({
      ...prev,
      search: e.target.value,
      page: 0,
    }));
  };



  return (
    <GridContainer style={{ padding: "0px 15px !important" }}>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openAdminCat}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
        >
          <GridItem xs={12} sm={12} md={12}>
            <DialogTitle id="scroll-dialog-title">{"Add From Default Categories"}</DialogTitle>
            <Divider />
            <DialogContent>
              <GridContainer>
                <ImageGallery list={adminCatogories} adminAddMode={true} addFromAdmin={addFromAdmin}/>
              </GridContainer>
            </DialogContent>
            {loader ? (
              <center>
                <CircularProgress align="centre" color="primary" />
              </center>
            ) : (
              ""
            )}
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                align="centre"
                variant="contained"
                onClick={resetForm}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                align="centre"
                variant="contained"
                onClick={() => { setOpenAdminCat(false); setOpen(true) }}
              >
                {"Add Custom Category"}
              </Button>
            </DialogActions>
          </GridItem>
        </Dialog>


        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
        >
          <GridItem xs={12} sm={12} md={12}>
            <DialogTitle id="scroll-dialog-title">{(selectedCategory && selectedCategory.id) ? "Update Category" : "Add Category"}</DialogTitle>
            <Divider />
            <DialogContent>
              <GridContainer>
                <form>
                  <Grid container={true} spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name={"category_name"}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                          formState,
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            name="category_name"
                            error={!!error}
                            onChange={(e) => {
                              setValue("category_name", e.target.value);
                              setSelectedCategory({
                                ...selectedCategory,
                                category_name: e.target.value
                              });
                            }}
                            value={selectedCategory.category_name}
                            fullWidth
                            label={"Category Name"}
                          />
                        )}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.category_name?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputLabel style={{ color: errors.category_image ? 'red' : 'black' }}>Category Image *</InputLabel>
                      <input
                        category_name="category_image"
                        onChange={fileValidator}
                        error={errors.category_image ? true : false}
                        accept="image/*"
                        type="file"
                      />
                      <br />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.category_image?.message}
                      </Typography>
                    </Grid>
                    {/* <GridItem md={6}>
                      <ControlledAutocomplete
                        id="available_days"
                        name="available_days"
                        value={selectedCategory.available_days}
                        control={control}
                        _onChange={setAvilableDaysSlot}
                        getOptionLabel={(option) => `${option}`}
                        options={avilableDaysData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Available Days"
                            error={errors.available_days ? true : false}
                          />
                        )}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.available_days?.message}
                      </Typography>
                    </GridItem>
                    <GridItem md={6}>
                      <ControlledAutocomplete
                        id="available_time_slot"
                        name="available_time_slot"
                        value={selectedCategory.available_time_slot}
                        control={control}
                        _onChange={setAvilableTimeSlot}
                        getOptionLabel={(option) => `${option}`}
                        options={avilableTimeSlot}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Available Time slot"
                            error={errors.available_time_slot ? true : false}
                          />
                        )}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.available_time_slot?.message}
                      </Typography>
                    </GridItem> */}
                  </Grid>

                </form>
              </GridContainer>
            </DialogContent>
            {loader ? (
              <center>
                <CircularProgress align="centre" color="primary" />
              </center>
            ) : (
              ""
            )}
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                align="centre"
                variant="contained"
                onClick={resetForm}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                align="centre"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                {(selectedCategory && selectedCategory.id) ? "Update Category" : "Add Category"}
              </Button>
            </DialogActions>
          </GridItem>
        </Dialog>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardCategoryWhite}></p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Menu Category List</h4>

              <Button
                onClick={() => setOpenAdminCat(true)}
                className="title-button"
                style={{
                  // backgroundColor: "#333252",
                  // backgroundColor: "white",
                  background:
                    "linear-gradient(to left,rgb(197,43,113),rgb(206,58,94), rgb(216,74,74),rgb(227,93,50))",
                  color: "white",
                  // color: "#a165e0c7",
                  fontWeight: "540",
                  fontSize: "14px",
                }}
              >
                Add Category
              </Button>
            </div>
          </CardHeader>
          <CardBody style={{ height: '500px' }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                marginRight: "1.5rem",
                marginBottom: " 1.20rem",
              }}
            >
              <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                name="search"
                value={tableParams.search}
                id="standard-search"
                label="Search"
                type="search"
                variant="standard"
                onChange={handleSearch}
              />
            </Box>
            <ImageGallery list={categoryList} deleteCategory={deleteCategory} initiateEdit={initiateEdit} />
          </CardBody>
          <TablePagination
            component="div"
            count={recordCount}
            page={tableParams.page}
            onPageChange={handlePagination}
            rowsPerPage={tableParams.limits}
            onRowsPerPageChange={handleLimit}
          />
        </Card>
      </GridItem>

      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </GridContainer>
  );
}
