import React, { Fragment, useEffect, useState } from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import Navbar from "./Navbar";
import "./register.css";
import VerticalLinearStepper from "./Stepper";
import RegistrationForm from "./RegistrationForm";
import Api from "../../Api/ApiUtils";
import Snackbar from "../../components/Snackbar/Snackbar";
import { useHistory } from "react-router-dom";

const BoxTitle = styled(Typography)(({ theme, color }) => ({
  fontFamily: "Manrope !important",
  fontStyle: "normal",
  fontWeight: "800",
  lineHeight: "46px",
  marginBottom: "8px",
  marginTop: "24px",
  color: color ?? theme.palette.otherColor.lightBlack,
  [theme.breakpoints.down("md")]: {
    fontWeight: 600,
    lineHeight: "36px",
    marginTop: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontWeight: 400,
    lineHeight: "26px",
    marginTop: "12px",
  },
}));
const Register = () => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [br, setBR] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    message: "",
  });

  const handleCloseMsg = () => {
    setBR(false);
  };

  useEffect(() => {
    countryList();
    getSubscriptionList();
  }, []);

  const getSubscriptionList = () => {
    Api.subscriptionPlansList({ limits: 4, page: 0 })
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.data.data) {

          setSubscriptionList(res.data.data.rows);
        }
      })

      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const countryList = () => {
    Api.countriesList()
      .then((res) => {
        if (res.data.status == 1) {
          setCountries(res.data.data.rows);
        }
      })

      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const handleCountryChange = (value) => {
    if (value && value.id) {
      const payload = {
        country_id: value.id,
      };
      Api.statesList(payload)
        .then((res) => {
          if (res.data.status === 1) {
            setStates(res.data.data.rows);
          }
        })
        .catch((err) => {
          if (err && err.msg) {
          }
        });
    }
  };

  const handleStateChange = (countryId, value) => {
    //console.log("e--value-->", e, value);
    if (value && value.id) {
      const payload = {
        country_id: countryId,
        state_id: value.id,
      };
      Api.citiesList(payload)
        .then((res) => {
          if (res.data.status == 1) {
            setCities(res.data.data.rows);
          }
        })

        .catch((err) => {
          if (err && err.msg) {
          }
        });
    }
  };

  const handleRegSubmit = (payload, e) => {
    e.preventDefault();
    Api.registerUser(payload)
      .then((res) => {
        if (res.data.status === 1) {
          setTimeout(() => {
            setBR(true);
            setNotification({
              type: "success",
              message: res.data.msg,
            });
          }, 1000);
          setTimeout(() => {
            history.push("/superadmin/login")
          }, 3000);
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          setBR(true);
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  };

  return (
    <Fragment className="signup-wrapper">
      <Navbar />
      <Container
        fixed
        sx={{
          // maxHeight: { lg: "500px", md: "100%", sm: "100%" },
          padding: "0 !important",
          maxWidth: "100% !important",
          background:
            "linear-gradient(180deg, #C72970 0%, #E55D3E 100%) !important",
          marginTop: { lg: "auto", md: "auto", sm: "auto", xs: "0px" },
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <div className="experience-item">
              <Box sx={{ textAlign: "left" }}>
                <BoxTitle variant="h5" color="#FFF">
                  Register in HAMRO STAY
                </BoxTitle>
                <br />
                <VerticalLinearStepper />
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} lg={8} md={8} sm={12} className="formSession">
            <RegistrationForm
              countries={countries}
              states={states}
              cities={cities}
              subscriptions={subscriptionList}
              handleCountryChange={handleCountryChange}
              handleStateChange={handleStateChange}
              handleRegSubmit={handleRegSubmit}
            />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </Fragment>
  );
};

export default Register;
