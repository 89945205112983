import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Api from "Api/ApiUtils";
import InputLabel from "@mui/material/InputLabel";
import { Select, MenuItem } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { object } from 'prop-types';
export default function ImageGallery(props) {

  let modelWidth = 500;
  const [selectedState, setState] = React.useState(false);
  const [selectedCity, setCity] = React.useState(false);
  const [cityList, setCityList] = React.useState([]);
  const [searchHotel, setHotelSearch] = React.useState(false);
  const [filteredRestaurants, setFilteredRestaurants] = React.useState([]);

  React.useEffect(() => {
    getFilteredValue();
  }, [selectedState, selectedCity, searchHotel]);

  React.useEffect(() => {
    setFilteredRestaurants(props.list);
  }, [props]);

  const getFilteredValue = () => {
    let filterdData = props.list;
    if (searchHotel) {
      let seachVal = searchHotel.toUpperCase();
      filterdData = filterdData.filter(x => x['super_admin_users.master_hotel.hotel_name']?.toUpperCase().indexOf(seachVal) > -1);
    }
    if (selectedCity)
      filterdData = filterdData.filter(x => x['super_admin_users.city'] == selectedCity);
    if (selectedState)
      filterdData = filterdData.filter(x => x['super_admin_users.state'] == selectedState);
    setFilteredRestaurants(filterdData);
  }

  const handleStateChange = (e) => {
    const val = e.target.value;
    if (val == "") {
      setState(false);
      setCity(false);
      setCityList([]);
      //getFilteredValue();
    }
    else {
      setState(val);
      setCity(false);
      setCityList(props.locationInfo[val]);
      //getFilteredValue();
    }
  };

  const handleCityChange = (e) => {
    const val = e.target.value;
    if (val == "") {
      setCity(false);
      //getFilteredValue();
    }
    else {
      setCity(val);
      //getFilteredValue();
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    //if (val.length > 3)
    setHotelSearch(val);
  }


  return (
    <GridContainer style={{
      maxHeight: "500px", overflowY: "scroll", width: "100%"
    }}>
      <GridItem md={2} sx={{ height: "30px" }}>
        <InputLabel id="demo-simple-select-standard-label">
          State: &nbsp; &nbsp;
        </InputLabel>

        <Select
          name="sortField"
          //value={tableParams.sortField}
          onChange={handleStateChange}
        >
          <MenuItem value="">All</MenuItem>
          {Object.keys(props.locationInfo).map(state => {
            return <MenuItem value={state}>{state}</MenuItem>
          })}
          {/*}  <MenuItem value="description">Description</MenuItem>*/}
        </Select>
      </GridItem>
      <GridItem md={2} sx={{ height: "30px" }}>
        <InputLabel id="demo-simple-select-standard-label">
          &nbsp; City: &nbsp; &nbsp;
        </InputLabel>
        <Select
          name="sortValue"
          disabled={!selectedState}
          //value={tableParams.sortValue}
          onChange={handleCityChange}
        >
          <MenuItem value="">All</MenuItem>
          {
            cityList.map(city => {
              return <MenuItem value={city}>{city}</MenuItem>
            })
          }
        </Select>
      </GridItem>
      <GridItem md={8} sx={{ height: "30px" }}></GridItem>
      <GridItem md={6}>
        <TextField
          name="search"
          //value={}
          id="standard-search"
          label="Hotel Name"
          type="search"
          variant="standard"
          onChange={handleSearch}
        />
      </GridItem>
      <GridItem md={6}></GridItem>

      {
        filteredRestaurants?.map((restaurant) => {
          return <GridItem md={3} style={{ marginBottom: "10px", padding: "10px", marginTop: "20px" }}><Card sx={{ maxWidth: modelWidth, height: "350px" }} key={restaurant.id}>
            <CardMedia
              component="img"
              alt={restaurant.profile_image}
              height="120"
              image={restaurant.profile_image}
            />
            <CardContent sx={{ maxWidth: modelWidth, height: "100px" }}>
              <Typography gutterBottom variant="h5" component="div">
                {restaurant['super_admin_users.master_hotel.hotel_name']}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <b>Contact Number:</b> {restaurant['mobile_num']}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <b>Address:</b> {restaurant['super_admin_users.address']}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <b>City:</b> {restaurant['super_admin_users.city']}
              </Typography>
            </CardContent>
            <CardActions style={{ bottom: 10 }}>
              {!props.adminAddMode && (
                <React.Fragment>
                  <Button size="small" hidden={props.adminAddMode} onClick={() => props.initiateEdit(restaurant)}><EditIcon style={{ color: "#333252" }} /></Button>
                  <Button size="small" hidden={props.adminAddMode} onClick={() => props.deleterestaurant(restaurant.id)}><DeleteIcon style={{ color: "#333252" }} /></Button>
                </React.Fragment>
              )}
              {props.adminAddMode && (
                <React.Fragment>
                  <Button color="primary" style={{ top: "50px" }} size="small" onClick={() => props.addRestaurantMapping(restaurant)}>Map Restaurant</Button>
                </React.Fragment>
              )}
            </CardActions>
          </Card></GridItem>
        })
      }
    </GridContainer >
  );
}