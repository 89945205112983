import React, { forwardRef, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import planBg from "../../assets/img/plan-bg.png";
import "../LandingPage/landing.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  MainTitle,
  ServeDescription,
  ServeTitle,
} from "../LandingPage/Css_Main";
import { ReactComponent as TickIcon } from "../../assets/img/tick.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/close.svg";

import CheckIcon from "@mui/icons-material/Check";
import { Diversity2 } from "@mui/icons-material";
import { setIn } from "formik";

const PlanType = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "19px",
  color: theme.palette.otherColor.lightBlack,
}));

const SubscriptionPlanSlider = forwardRef(({ subscriptions, updateSubscriptionFormVal }, ref) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#FF6C23",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      // backgroundColor:
      //   theme.palette.mode === "dark"
      //     ? "rgba(255,255,255,.35)"
      //     : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const updateSelection = (tier) => {
    setSelectedSubscription(tier.id);
    updateSubscriptionFormVal(tier);
  }
  return (
    <>
      <div
        style={{ backgroundImage: `url(${planBg})` }}
        className="plan-section-wrap"
        id="pricing"
        ref={ref}
      >
        <Container className="billBoxes" fixed component="main">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            className="priceBoxes"
          >
            {subscriptions.map((tier) => (
              <Grid
                item
                key={tier.name}
                xs={12}
                sm={5}
                md={5}
                lg={2}
                justifyContent="center"
              >
                <div
                  className={
                    selectedSubscription !== 0 &&
                      selectedSubscription == tier.id
                      ? "plan-item activeplan-item"
                      : "plan-item"
                  }
                >
                  <div className="plan-item__header">
                    <h2
                      className={
                        tier.price_per_month == 0 ? "priceanimate-charcter" : ""
                      }
                    >
                      {tier.name}
                    </h2>
                  </div>
                  <div className="plan-item__body">
                    <ul>
                      {tier.supported_services.split(",").map((line) => (
                        <li className={"active"} key={line.toString()}>
                          <div className="icon">
                            <TickIcon />
                          </div>
                          {line}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="plan-item__footer">
                    <div className="plan-item__price ">
                      {tier.price_per_month !== 0 ? (
                        <>
                          {" "}
                          <span>
                            <sub>$</sub> {tier.price_per_month}
                          </span>{" "}
                          /month{" "}
                        </>
                      ) : (
                        " "
                      )}
                    </div>
                    <Button
                      fullWidth
                      className="plan-button"
                      onClick={() => updateSelection(tier)}
                    >
                      {selectedSubscription == tier.id
                        ? "Selected Plan"
                        : "Choose Plan"}
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
});

export default SubscriptionPlanSlider;
