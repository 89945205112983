import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../Register/Navbar";
import { useForm, Controller } from "react-hook-form";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Logo from "../../assets/img/hotel-logo.png";
import Snackbar from "../../components/Snackbar/Snackbar";
import { Link } from "react-router-dom";
import Api from "../../Api/ApiUtils";
import { useHistory } from "react-router-dom";
const defaultForm = { user_email: "" };


const styles = {
  boxStyle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    "& > :not(style)": {
      m: 1,
      width: 500,
      height: 500,
    },
  },
  buttonBoxStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarStyle: { padding: 20 },
};
const ForgotPassword = () => {
  const history = useHistory();
  const [br, setBR] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    message: "",
  });

  const validationSchema = Yup.object().shape({
    user_email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const resetForm = () => {
    reset(
      {
        user_email: "",
      }
    );
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    handleFormSubmit(data, e);
  };

  const handleCloseMsg = () => {
    setBR(false);
  };

  const handleFormSubmit = (payload, e) => {
    e.preventDefault();
    Api.forgotPassword(payload)
      .then((res) => {
        resetForm();
        if (res.data.status === 1) {
          setTimeout(() => {
            setBR(true);
            setNotification({
              type: "success",
              message: res.data.msg,
            });
          }, 1000);
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          setBR(true);
          resetForm();
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  };

  return (
    <Fragment>
      <Navbar />
      <Box sx={styles.boxStyle}>
        <Paper elevation={3}>
          <Grid align="center">
            <Link to="/">
              <img src={Logo} style={styles.avatarStyle} alt="fireSpot" />
            </Link>
            <h2>Forgot password</h2>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box px={3} py={2}>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    id="user_email"
                    name="user_email"
                    render={({ field }) => <TextField {...field} label="Email"  fullWidth/>}
                    control={control}
                    margin="dense"
                    rules={{ required: true }}
                    defaultValue=""
                    error={errors.user_email ? true : false}
                  />
                  {/* <TextField
                    required
                    id="user_email"
                    name="user_email"
                    label="Email"
                    fullWidth
                    margin="dense"
                    {...register("user_email")}
                    error={errors.user_email ? true : false}
                  /> */}
                  <Typography variant="inherit" color="textSecondary">
                    {errors.user_email?.message}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} sx={styles.buttonBoxStyle}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </Fragment>
  );
};

export default ForgotPassword;
