import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../Register/Navbar";
import { useForm, Controller } from "react-hook-form";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Logo from "../../assets/img/hotel-logo.png";
import Snackbar from "../../components/Snackbar/Snackbar";
import { Link, useHistory } from "react-router-dom";
import Api from "../../Api/ApiUtils";

const defaultForm = {
  password: "",
  confirmpassword: "",
  username: "",
  forget_pass_uuid: "",
};
const styles = {
  boxStyle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    "& > :not(style)": {
      m: 1,
      width: 500,
      height: 500,
    },
  },
  buttonBoxStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarStyle: { padding: 20 },
};
const ResetPassword = () => {
  const history = useHistory();
  const [br, setBR] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    message: "",
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("New Password is required").min(6),
    confirmpassword: Yup.string()
      .required("Confirm Password is required")
      .min(6)
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    username: Yup.string(),
    forget_pass_uuid: Yup.string(),
  });
  const {
    reset,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(window.location.search);
    const username = queryParams.get("username");
    const forget_pass_uuid = queryParams.get("forget_pass_uuid");
    if (username && forget_pass_uuid) {
      setValue("username", username);
      setValue("forget_pass_uuid", forget_pass_uuid);
      handleFormSubmit();
    }
  };

  const resetForm = () => {
    // setValue("username", "");
    // setValue("forget_pass_uuid", "");
    // setValue("password", "");
    // setValue("confirmpassword", "");
    reset({
      username: "",
      forget_pass_uuid: "",
      password: "",
      confirmpassword: "",
    });
  };

  const handleCloseMsg = () => {
    setBR(false);
  };

  const handleFormSubmit = () => {
    let payload = getValues();
    Api.resetPassword(payload)
      .then((res) => {
        resetForm();
        if (res.data.status === 1) {
          setTimeout(() => {
            setBR(true);
            setNotification({
              type: "success",
              message: res.data.msg,
            });
          }, 1000);
          setTimeout(() => {
            history.push("/superadmin/login")
          }, 3000);
        } else {
          setBR(true);
          setNotification({
            type: "danger",
            message: res.data.msg,
          });
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          resetForm();
          setBR(true);
          setNotification({
            type: "danger",
            message: err.msg,
          });
        }
      });
  };

  return (
    <Fragment>
      <Navbar />
      <Box sx={styles.boxStyle}>
        <Paper elevation={3}>
          <Grid align="center">
            <Link to="/">
              <img src={Logo} style={styles.avatarStyle} alt="fireSpot" />
            </Link>
            <h2>Reset password</h2>
          </Grid>
          <Box px={3} py={2}>
            <Grid container={true} spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  id="password"
                  name="password"
                  render={({ field }) => (
                    <TextField {...field} label="New password" type="password" fullWidth />
                  )}
                  control={control}
                  margin="dense"
                  defaultValue=""
                  error={errors.password ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.password?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  id="confirmpassword"
                  name="confirmpassword"
                  render={({ field }) => (
                    <TextField {...field} label="Confirm password" type="password" fullWidth />
                  )}
                  control={control}
                  margin="dense"
                  defaultValue=""
                  error={errors.confirmpassword ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.confirmpassword?.message}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} sx={styles.buttonBoxStyle}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              SUBMIT
            </Button>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        place="tr"
        setBR={setBR}
        color={notification.type}
        message={notification.message}
        open={br}
        closeNotification={handleCloseMsg}
        close
      />
    </Fragment>
  );
};

export default ResetPassword;
