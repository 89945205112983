import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./register.css";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import moment from "moment";
import Api from "../../Api/ApiUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SubscriptionPlanSlider from "./SubscriptionPlanSlider";
import Autocomplete from "@mui/material/Autocomplete";
import Popup from "components/Popup/Popup.js";

const Menusx = {
  display: "block",
  maxWidth: "100% !important",
};
function checkIfFilesAreTooBig(files) {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
}

function checkIfFilesAreCorrectType(files) {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const ControlledAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  _onChange,
  control,
  defaultValue,
  name,
  renderOption,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => _onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

const RegistrationForm = ({
  countries,
  states,
  cities,
  subscriptions,
  handleCountryChange,
  handleStateChange,
  handleRegSubmit,
}) => {
  const [uniqueEmail, setEmailStatus] = useState(null);
  const validationSchema = Yup.object().shape({
    user_type: Yup.string().required("Business Identification is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    hotel_name: Yup.string().required("Company Name is required"),
    mobile_num: Yup.number()
      .required("Contact number is required")
      .integer("Invalid number"),
    country_id: Yup.number().required("Country Name is required"),
    state_id: Yup.number().required("State Name is required"),
    city_id: Yup.number().required("City Name is required"),
    subscription_id: Yup.number().required("Choose subscription plan"),
    sub_start_date: Yup.string(),
    sub_end_date: Yup.string(),
    payment_date: Yup.string(),
    set_user_limit: Yup.number(),
    set_staff_limit: Yup.number(),
    role_id: Yup.number(),
    amount: Yup.number(),
    address: Yup.string().required("Company Address is required"),
    id_number: Yup.string().required("Pan Number is required"),
    id_proof: Yup.mixed().required("Required"),
    // .test(
    //   "is-correct-file",
    //   "VALIDATION_FIELD_FILE_BIG",
    //   checkIfFilesAreTooBig
    // )
    // .test(
    //   "is-big-file",
    //   "VALIDATION_FIELD_FILE_WRONG_TYPE",
    //   checkIfFilesAreCorrectType
    // ),
    referal_code: Yup.string(),
    acceptTerms: Yup.bool().oneOf([true], "Accepting Terms is required"),
    currency: Yup.string(),
    currency_name: Yup.string(),
    currency_symbol: Yup.string()
  });
  const {
    register,
    setValue,
    getValues,
    trigger,
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const watchFields = watch("email");

  React.useEffect(() => {
    if (watchFields && watchFields.length > 3 && validateEmail(watchFields)) {
      if (!errors.email) checkEmail();
    }
  }, [watchFields]);

  const [modalContext, setModalContext] = React.useState({
    show: false,
    type: "termsandcondition",
  });

  const onSubmit = (data, e) => {
    console.log("calll");
    e.preventDefault();
    handleRegSubmit(data, e);
    //console.log(JSON.stringify(data, null, 2));
  };
  const setBusinessType = (value) => {
    setValue("user_type", value);
    trigger("user_type");
  };
  const updateCountryValue = (value) => {
    setValue("country_id", value.id);
    setValue("currency", value?.currency);
    setValue("currency_name", value?.currency_name);
    setValue("currency_symbol", value?.currency_symbol);
    handleCountryChange(value);
    trigger("country_id");
  };
  const updateStateValue = (value) => {
    setValue("state_id", value.id);
    handleStateChange(getValues("country_id"), value);
    trigger("state_id");
  };
  const updateCityValue = (value) => {
    setValue("city_id", value.id);
    trigger("city_id");
  };
  const updateSubscriptionFormVal = (plan) => {
    setValue("subscription_id", plan.id);
    setValue("sub_start_date", moment().format("YYYY-MM-DD"));
    setValue(
      "sub_end_date",
      moment().add(plan.duration, "day").format("YYYY-MM-DD")
    );
    setValue("amount", plan.price_per_month);
    setValue("payment_date", moment().format("YYYY-MM-DD"));
    setValue("role_id", 2);
    setValue("set_user_limit", plan.user_limit);
    setValue("set_staff_limit", plan.staff_limit);
    trigger("subscription_id");
  };
  const fileValidator = (e) => {
    setValue("id_proof", e.target.files[0]);
    trigger("id_proof");
  };

  const userTypes = ["Hotel", "Restaurant", "Service provider"];
  const checkEmail = () => {
    let currentEmail = getValues("email");

    if (currentEmail.length > 3 && !errors.email && validateEmail(watchFields))
      Api.validateSignupEmail({ email_id: currentEmail })
        .then((res) => {
          if (res && res?.data?.msg && res?.data?.code === 422) {
            //setError('email', { type: res?.data?.code, message: res?.data?.msg });
            setEmailStatus("invalid");
          } else if (res && res?.data?.msg && res?.data?.code === 200) {
            setEmailStatus("valid");
          }
        })
        .catch((err) => {
          if (err && err.data.msg) {
            setEmailStatus("invalid");
            //setError('email', { type: 'custom', message: err.data.msg });
            //console.log(err);
          }
        });
  };

  return (
    <Fragment>
      <Popup
        openModal={modalContext}
        closeModal={() =>
          setModalContext((previousContext) => ({
            ...previousContext,
            show: false,
          }))
        }
      />
      <Paper>
        <Box px={3} py={2}>
          <Typography variant="h6" margin="dense" gutterBottom={true}>
            1. Contact Information
          </Typography>

          <Grid container={true} spacing={2} px={3} py={2}>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete
                id="user_type"
                name="user_type"
                control={control}
                _onChange={setBusinessType}
                getOptionLabel={(option) => `${option}`}
                options={userTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="I am a *"
                    error={errors.user_type ? true : false}
                  />
                )}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.user_type?.message}
              </Typography>
            </Grid>
            <Grid item xs={0} sm={8}></Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="first_name"
                name="first_name"
                label="First Name"
                fullWidth
                margin="dense"
                {...register("first_name")}
                error={errors.first_name ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.first_name?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="last_name"
                name="last_name"
                label="Last Name"
                fullWidth
                margin="dense"
                {...register("last_name")}
                error={errors.last_name ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.last_name?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="dense"
                {...register("email")}
                error={errors.email || uniqueEmail === "invalid" ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.email?.message}{" "}
                {!errors.email && uniqueEmail === "invalid"
                  ? "Email is taken"
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h6" margin="dense">
            2. Business Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="hotel_name"
                name="hotel_name"
                label="Company Name"
                fullWidth
                margin="dense"
                {...register("hotel_name")}
                error={errors.hotel_name ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.hotel_name?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="mobile_num"
                name="mobile_num"
                label="Contact number"
                fullWidth
                margin="dense"
                {...register("mobile_num")}
                error={errors.mobile_num ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.mobile_num?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete
                id="country_id"
                name="country_id"
                control={control}
                options={countries}
                _onChange={updateCountryValue}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Country *"
                    error={errors.country_id ? true : false}
                  />
                )}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.country_id?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete
                id="state_id"
                name="state_id"
                control={control}
                options={states}
                _onChange={updateStateValue}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company State *"
                    error={errors.state_id ? true : false}
                  />
                )}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.state_id?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete
                id="city_id"
                name="city_id"
                control={control}
                options={cities}
                _onChange={updateCityValue}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company City *"
                    error={errors.city_id ? true : false}
                  />
                )}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.city_id?.message}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="address"
                name="address"
                label="Company Address"
                fullWidth
                margin="dense"
                {...register("address")}
                error={errors.address ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.address?.message}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h6" margin="dense">
            3. Choose Subscription Plan
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <br />
              <SubscriptionPlanSlider
                subscriptions={subscriptions}
                updateSubscriptionFormVal={updateSubscriptionFormVal}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.subscription_id ? errors.subscription_id.message : ""}
              </Typography>
              <Grid item xs={12} sx={{ display: "none" }}>
                <input
                  type="hidden"
                  id="subscription_id"
                  name="subscription_id"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h6" margin="dense">
            4.Identification submission
          </Typography>
          <Grid container spacing={1}>
            <br />
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="id_number"
                name="id_number"
                label="Pan Number"
                fullWidth
                margin="dense"
                {...register("id_number")}
                error={errors.id_number ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.id_number?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel>PAN Permit Upload *</InputLabel>
              <input
                name="id_proof"
                onChange={fileValidator}
                accept="image/*, application/pdf"
                multiple
                type="file"
              />
              <br />
              <Typography variant="inherit" color="textSecondary">
                {errors.id_proof?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="referal_code"
                name="referal_code"
                label="Referal Code"
                fullWidth
                margin="dense"
                {...register("referal_code")}
                error={errors.referal_code ? true : false}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.referal_code?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="acceptTerms"
                    defaultValue="false"
                    inputRef={register()}
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        color="primary"
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Typography
                    color={errors.acceptTerms ? "error" : "inherit"}
                    onClick={() => {
                      setModalContext({
                        show: true,
                        type: "termsandcondition",
                      });
                    }}
                  >
                    I have read and agree to the Terms *
                  </Typography>
                }
              />
              <br />
              <Typography variant="inherit" color="textSecondary">
                {errors.acceptTerms
                  ? "(" + errors.acceptTerms.message + ")"
                  : ""}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={ uniqueEmail === "invalid"}
              onClick={handleSubmit(onSubmit)}
            >
              Apply Now
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default RegistrationForm;
