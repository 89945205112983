import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Api from "Api/ApiUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0.5px solid",
  boxShadow: 24,
  height: 300,
  p: 4,
  overflowY: "scroll",
  overflowX: "hidden"
};

const btnstyle = {
  margin: "221px 0px"
};
export default function Popup({ openModal: { type, show }, closeModal }) {
  const user = JSON.parse(localStorage.getItem("ContentList"));
  let content = {
    privacyPolicy: "Yet to be updated",
    termsandcondition: "Yet to be updated"
  }
  if (user && user.data && user.data.length > 0) {
    const privacyPolicy = user.data.filter(x => x.type === "privacyPolicy");
    const termsandcondition = user.data.filter(x => x.type === "termsCondition");
    content = {
      privacyPolicy: privacyPolicy.length > 0 ? privacyPolicy[0].content : content.privacyPolicy,
      termsandcondition: termsandcondition.length > 0 ? termsandcondition[0].content : content.termsandcondition
    }

  }


  const handleClose = () => {
    closeModal();
    console.log("Handle close clicked ");
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div dangerouslySetInnerHTML={{ __html: content[type] }}></div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>

        </Typography>
        <Button
          onClick={handleClose}
          color="secondary"
          style={btnstyle}
          variant={"contained"}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
