import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Api from "Api/ApiUtils";

export default function ImageGallery(props) {

  let modelWidth = (props.adminAddMode)? 500 : 345;
  return (
    <GridContainer style={{
      height: "500px", overflowY: "scroll", width: "100%"
    }}>
      {
        props.list?.map((category) => {
          return <GridItem md={3} style={{ marginBottom: "10px", padding: "10px" }}><Card sx={{ maxWidth: modelWidth, height: "250px" }} key={category.id}>
            <CardMedia
              component="img"
              alt={category.name}
              height="100"
              image={category.image}
            />
            <CardContent sx={{ maxWidth: modelWidth, height: "50px" }}>
              <Typography gutterBottom variant="h5" component="div">
                {category.name}
              </Typography>
              {/* <Typography variant="body2" color="text.secondary">
                Available Date: {category.available_days}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Available Time: {category.available_time_slot}
              </Typography> */}
            </CardContent>
            <CardActions style={{ bottom: 10 }}>
              {!props.adminAddMode && (
                <React.Fragment>
                  <Button size="small" hidden={props.adminAddMode} onClick={() => props.initiateEdit(category)}><EditIcon style={{ color: "#333252" }} /></Button>
                  <Button size="small" hidden={props.adminAddMode} onClick={() => props.deleteCategory(category.id)}><DeleteIcon style={{ color: "#333252" }} /></Button>
                </React.Fragment>
              )}
              {props.adminAddMode && (
                <React.Fragment>
                  <Button color="primary"  size="small"  onClick={() => props.addFromAdmin(category)}>Add to my Categories</Button>
                </React.Fragment>
              )}
            </CardActions>
          </Card></GridItem>
        })
      }
    </GridContainer >
  );
}