import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {
    Grid
} from "@material-ui/core";
import Api from "Api/ApiUtils";
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

export default function OrderOptions(props) {

    const addSubOption = (category) => {
        let title = document.getElementById(`${category.id}-category`)?.value;
        let price = document.getElementById(`${category.id}-price`)?.value;
        if (title && title.length > 0 && price && price.length > 0 && isNumeric(price)) {
            props.addChild(title, price, category?.id);
            document.getElementById(`${category.id}-category`).value = "";
            document.getElementById(`${category.id}-price`).value = "";
        }
    }
    return (
        <GridContainer style={{
            maxHeight: "500px", overflowY: "scroll"
        }}>
            {
                props.data?.map((category, key) => {
                    return <GridItem md={4} style={{ marginBottom: "10px", padding: "10px" }}><Card sx={{ maxWidth: 345 }} key={category.id}>
                        <CardContent sx={{ maxWidth: 345 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                {category.name} {!(props.viewMode) && <Button size="small" hidden={props.viewMode} onClick={() => props.removeCategory(category.id)}><DeleteIcon hidden={props.viewMode} style={{ color: "#333252" }} /></Button>

                                }</Typography>
                            <List>
                                {category.children.map((sub, index) => {
                                    return <ListItem key={sub}
                                        secondaryAction={
                                            !(props.viewMode) && <IconButton hidden={props.viewMode} edge="end" aria-label="delete">
                                                <DeleteIcon onClick={() => props.removeCategoryChildren(category.id, sub.sid)} />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={sub.title}
                                        />
                                        <ListItemText
                                            primary={sub.price}
                                        />
                                    </ListItem>
                                })}

                            </List>
                        </CardContent>
                        <CardContent sx={{ maxWidth: 345, height: "130px" }} hidden={props.viewMode}>
                            <label for="options">Add Sub Options : </label>
                            <Grid container={true} spacing={2}>
                                <Grid item xs={5} sm={5}>
                                    <TextField
                                        id={`${category.id}-category`}
                                        name="search"
                                        label="Title"
                                        type="search"
                                        variant="standard"
                                    //onChange={(e) => { addSubOption(e.target.value, category) }}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <TextField
                                        id={`${category.id}-price`}
                                        name="search"
                                        label="Price"
                                        type="search"
                                        variant="standard"
                                    //onChange={(e) => { addSubOption(e.target.value, category) }}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Button
                                        hidden={props.viewMode}
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => addSubOption(category)}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>



                        </CardContent>
                    </Card></GridItem>
                })
            }
        </GridContainer >
    );
}